import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import PermissionsPicker from '../../../account/users/dialogs/PermissionsPicker';

const AddUserModal = ({ shown, email, name, handleHide, handleChange, handleNameChange, onSubmit }) => {
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [loadingButton, setLoadingButton] = useState(null);

    const handleInvite = async e => {
        e.preventDefault();
        setLoadingButton('addMore');
        await onSubmit(selectedPermissions);
        setSelectedPermissions([]);
        setLoadingButton(null);
    };

    const handleInviteAndClose = async e => {
        e.preventDefault();
        setLoadingButton('close');
        await onSubmit(selectedPermissions);
        setSelectedPermissions([]);
        setLoadingButton(null);
        handleHide();
    };

    return (
        <Dialog
            open={shown}
            onClose={handleHide}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Invite user</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please enter the email address of the new user. This email address will receive an invitation to join the account.
                </DialogContentText>
                <FormGroup>
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Name"
                        autoComplete="off"
                        value={name}
                        onChange={handleNameChange}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        fullwidth
                        type="email"
                        variant="outlined"
                        size="small"
                        label="Email"
                        autoComplete="off"
                        value={email}
                        onChange={handleChange}
                        placeholder="name@example.com"
                    />
                </FormGroup>
                <PermissionsPicker
                    selectedPermissions={selectedPermissions}
                    onChange={setSelectedPermissions}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!email || loadingButton !== null}
                    onClick={handleInvite}
                    endIcon={loadingButton === 'addMore' ? <CircularProgress size={16} style={{ color: '#FFF' }} /> : null}
                >
                    {loadingButton === 'addMore' ? 'Working' : 'Invite & Add More'}
                </Button>
                <Button
                    disabled={!email || loadingButton !== null}
                    onClick={handleInviteAndClose}
                    endIcon={loadingButton === 'close' ? <CircularProgress size={16} style={{ color: '#FFF' }} /> : null}
                >
                    {loadingButton === 'close' ? 'Working' : 'Invite & Close'}
                </Button>
                <Button
                    onClick={handleHide}
                    variant="outlined"
                    disabled={loadingButton !== null}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserModal;
