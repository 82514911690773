import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuItem from '@mui/material/MenuItem';
import { red } from '@mui/material/colors';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import useApi from '../../hooks/useApi';
import NavigationItem from '../NavigationItem';
import { useDropzone } from 'react-dropzone';
import useSnackbar from '../../hooks/useSnackbar';
import useAccount from '../../hooks/useAccount';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';
import useNotifications from '../../hooks/useNotifications';
import AddFieldDialog from '../../account/customFields/AddFieldDialog';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import AllowedRecipientsList from '../AllowedRecipientsList';

// note auto detection of mappings is case insensitive so all mappings should be lowercase

const standardFields = [
    { name: 'Email Address', propertyName: 'emailAddress', mappings: ['emailaddress', 'email'] },
    { name: 'Title', propertyName: 'title', mappings: ['title', 'salutation'] },
    { name: 'First Name', propertyName: 'firstName', mappings: ['firstname', 'name'] },
    { name: 'Last Name', propertyName: 'lastName', mappings: ['lastname'] },
    { name: 'Company Name', propertyName: 'companyName', mappings: ['companyname', 'company'] },
    { name: 'Mobile Phone', propertyName: 'mobilePhone', mappings: ['mobilenumber', 'mobilephone', 'mobile'] },
    { name: 'Job Title', propertyName: 'jobTitle', mappings: ['jobtitle', 'job', 'role'] },
    { name: 'Telephone', propertyName: 'telNo', mappings: ['phonenumber', 'phone', 'telno', 'telephone'] },
    { name: 'Date of Birth', propertyName: 'dateOfBirth', mappings: ['dateofbirth', 'dob', 'birthday'] },
    { name: 'Address 1', propertyName: 'address1', mappings: ['address1', 'address'] },
    { name: 'Address 2', propertyName: 'address2', mappings: ['address2'] },
    { name: 'Address 3', propertyName: 'address3', mappings: ['address3'] },
    { name: 'City', propertyName: 'city', mappings: ['city'] },
    { name: 'County', propertyName: 'county', mappings: ['county'] },
    { name: 'Postcode', propertyName: 'postcode', mappings: ['postcode'] },
    { name: 'Country', propertyName: 'country', mappings: ['country'] }
];

const dropzoneLabelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

const supportedTypes = [
    { extension: 'csv', contentType: 'text/csv' },
    { extension: 'xls', contentType: 'application/vnd.ms-excel' },
    { extension: 'xlsx', contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
];

const excluded = { backgroundColor: red[50] };

const UploadDialog = ({ setImportData, setMappings, onNext, onClose }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { handleFetch } = useApi();
    const { showSnackbar } = useSnackbar();
    const { customFields, isInternalComms, allowedRecipients } = useAccount();

    const fields = [
        ...standardFields.map(f => ({
            ...f,
            type: 'standard'
        })),
        ...customFields.map(({ name }) => ({
            name,
            propertyName: name,
            mappings: [name.toLowerCase()],
            type: 'custom'
        }))
    ];

    const handleUpload = async acceptedFiles => {
        setIsSubmitting(true);

        const data = new FormData();
        data.append('file', acceptedFiles[0]);

        const response = await handleFetch('imports', {
            method: 'post',
            body: data
        });

        if (!response.ok) {
            showSnackbar(response.statusText, 'error');
            setIsSubmitting(false);
            return;
        }

        const importData = await response.json();

        // todo do some basic guesswork on mappings
        const defaultMappings = importData.previewData[0].map(header => {
            const field = fields.find(f => {
                const headerName = f.type === 'standard' ? header.toLowerCase().replace(/[^a-zA-Z0-9]/g, '') : header.toLowerCase();
                return f.mappings && f.mappings.some(m => m === headerName);
            });

            return field?.propertyName ?? '';
        });

        setImportData(importData);

        setMappings(defaultMappings);
        onNext();
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: supportedTypes.map(({ extension }) => `.${extension}`).join(', '),
        maxFiles: 1,
        disabled: isSubmitting,
        onDropAccepted: acceptedFiles => handleUpload(acceptedFiles)
    });

    const dropzoneStyle = {
        textAlign: 'center',
        cursor: isSubmitting ? 'default' : 'pointer',
        border: '2px dashed #e5e5e5',
        minHeight: 120,
        position: 'relative'
    };

    useEffect(() => {
        fileRejections.length > 0 && showSnackbar(fileRejections[0].errors[0].message, 'error');
    }, [fileRejections]);

    return (
        <Dialog
            onClose={onClose}
            open={true}
        >
            <DialogTitle>Import Contacts</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Container maxWidth={false} {...getRootProps()} sx={dropzoneStyle}>
                        <input {...getInputProps()} />
                        <Box sx={dropzoneLabelStyle}>
                            {isSubmitting ? <CircularProgress /> : <CloudUploadIcon fontSize="large" />}
                            <Typography
                                component="p"
                                variant="body1"
                            >
                                {isSubmitting ? 'Uploading' : 'Drag and drop file, or click to browse'}
                            </Typography>
                        </Box>
                    </Container>
                    {(isInternalComms && allowedRecipients.length > 0) && (
                        <AllowedRecipientsList allowedRecipients={allowedRecipients} />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const MappingsDialog = ({ importData, mappings, onChangeMapping, onBack, onNext, onClose }) => {
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [showCreateFieldDialog, setShowCreateGroupDialog] = useState(false);
    const [fields, setFields] = useState([]);
    const { customFields } = useAccount();

    // todo validate if email address is unmapped

    const handleNext = () => {
        if (mappings.includes('emailAddress')) {
            onNext();
        }
    };

    const handleShowAddFieldDialog = i => {
        setSelectedColumn(i);
        setShowCreateGroupDialog(true);
    };

    const handleAddField = async field => {
        setShowCreateGroupDialog(false);
        onChangeMapping(selectedColumn, field.name);
        setSelectedColumn(null);
    };

    useEffect(() => {
        setFields([
            ...standardFields,
            ...customFields.map(({ name }) => ({
                name: name,
                propertyName: name,
                mappings: [name.toLowerCase()]
            }))
        ]);
    }, [customFields]);

    if (showCreateFieldDialog) {
        return (
            <AddFieldDialog
                onSubmit={field => handleAddField(field)}
                onClose={() => {
                    // clear value if user cancels adding field
                    onChangeMapping(selectedColumn, '');
                    setShowCreateGroupDialog(false);
                }}
            />
        );
    }

    return (
        <Dialog
            maxWidth="xl"
            onClose={(e, reason) => (reason !== 'backgroupClick') && onClose}
            open={true}
        >
            <DialogTitle>Import Contacts</DialogTitle>
            <DialogContent>
                {importData.previewData.length > 0 && (
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {importData.previewData[0].map((column, i) => (
                                    <TableCell
                                        key={i}
                                        sx={mappings[i] === '' ? excluded : null}
                                    >
                                        <Select
                                            fullWidth
                                            size="small"
                                            value={mappings[i]}
                                            onChange={e => onChangeMapping(i, e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="">
                                                <em>Exclude</em>
                                            </MenuItem>
                                            <MenuItem value="temp" onClick={() => handleShowAddFieldDialog(i)}>
                                                <em>New Field</em>
                                            </MenuItem>
                                            {fields.map(({ name, propertyName }) => (
                                                <MenuItem
                                                    key={propertyName}
                                                    value={propertyName}
                                                    disabled={mappings.includes(propertyName)}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {importData.previewData.map((row, i) => (
                                <TableRow key={i}>
                                    {/* {i === 0 ? (
                                        <TableCell padding="checkbox">
                                            <Checkbox size="small" />
                                        </TableCell>
                                    ) : (
                                        <TableCell />
                                    )} */}
                                    {row.map((column, j) => (
                                        <TableCell
                                            key={j}
                                            sx={{
                                                ...(mappings[j] === '' ? excluded : null)
                                                //whiteSpace: 'nowrap',
                                                //textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {column}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <DialogActions>
                <Box sx={{ flexGrow: 1 }}>
                    <Button variant="outlined" onClick={onBack}>Back</Button>
                </Box>
                <Button onClick={handleNext}>Choose Groups</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const GroupsDialog = ({ selectedGroups, onCreateGroup, onChangeSelectedGroups, onBack, onNext, onClose }) => {
    return (
        <Dialog
            onClose={(e, reason) => (reason !== 'backgroupClick') && onClose}
            open={true}
        >
            <DialogTitle>Import Contacts</DialogTitle>
            <FilteredGroupsSelector
                selectedGroups={selectedGroups}
                setSelectedGroups={onChangeSelectedGroups}
                spacing={3}
                popperGutters={3}
                onCreateGroup={group => onCreateGroup(group)}
            />
            <DialogActions>
                <Box sx={{ flexGrow: 1 }}>
                    <Button variant="outlined" onClick={onBack}>Back</Button>
                </Box>
                <Button onClick={onNext}>Final Checks</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const FinalChecksDialog = ({ importData, groups, selectedGroups, onBack, onClose, onConfirm }) => {
    return (
        <Dialog
            onClose={(e, reason) => (reason !== 'backgroupClick') && onClose}
            open={true}
        >
            <DialogTitle>Import Contacts</DialogTitle>
            <DialogContent>
                <Typography paragraph>You are about to import {importData.numberOfContacts} contacts into {selectedGroups?.length === 1 ? '1 group:' : selectedGroups?.length > 0 ? `${selectedGroups.length} groups:` : 'your account.'}</Typography>
                {selectedGroups.length > 0 && (
                    <Paper sx={{ my: 2 }}>
                        <Collapse in={!groups}>
                            <LinearProgress />
                        </Collapse>
                        {groups && (
                            <List aria-label="Selected Groups" disablePadding>
                                {groups.filter(g => selectedGroups.includes(g.id)).map((g, i, arr) => (
                                    <NavigationItem
                                        key={g.id}
                                        item={g}
                                        type="group"
                                        divider={i + 1 < arr.length}
                                    />
                                ))}
                            </List>
                        )}
                    </Paper>
                )}
                <Typography>Are you sure you want to proceed?</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ flexGrow: 1 }}>
                    <Button variant="outlined" onClick={onBack}>Back</Button>
                </Box>
                <Button onClick={onConfirm}>I'm Sure</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const ImportCompleteDialog = ({ importId, onClose, onOpenImportHistory }) => {
    const { notifications } = useNotifications();
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const notification = notifications
            .filter(({ isRead, type }) => !isRead && type === 'import')
            .map(({ body }) => JSON.parse(body))
            .find(({ id }) => id === importId);

        if (notification) {
            setIsComplete(true);
        }
    }, [notifications]);

    return (
        <Dialog
            onClose={onClose}
            open={true}
        >
            <DialogTitle>Import Contacts</DialogTitle>
            <DialogContent>
                {isComplete ? (
                    <Typography>Import complete.</Typography>
                ) : (
                    <>
                        <Typography paragraph>Import in progress. You can safely close this window and you will be notified when your import is complete.</Typography>
                        <LinearProgress />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onOpenImportHistory} sx={{ marginRight: 1 }}>Import History</Button>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const ImportContactsDialog = ({ onClose, onCreateGroup, onOpenImportHistory, preSelectedGroups = [] }) => {
    const [step, setStep] = useState('upload');
    const [importData, setImportData] = useState();
    const [mappings, setMappings] = useState([]);
    const [removeHeader, setRemoveHeader] = useState(false);
    const [groups, setGroups] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState(preSelectedGroups);
    const { handlePut, handleGet } = useApi();
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (!importData?.previewData) return;

        const normalizedMappings = mappings.map(mapping => mapping.toLowerCase());

        const filteredPreviewData = importData.previewData.filter(row => {
            const normalizedRow = row.map(value => value?.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''));
            return !normalizedMappings.some(mapping => {
                if (!mapping) return false;
                return normalizedRow.includes(mapping);
            });
        });

        if (filteredPreviewData.length !== importData.previewData.length) {
            importData.numberOfContacts--;
            setRemoveHeader(true);
        }

        importData.previewData = filteredPreviewData;

    }, [importData, mappings]);

    const handleCompleteImport = async () => {
        setIsProcessing(true);

        const response = await handlePut(`imports/${importData.id}`, {
            okToImport: true,
            ...(selectedGroups && { groupIds: selectedGroups }),
            mappings: mappings
                .map((destinationField, columnNumber) => ({ columnNumber, destinationField }))
                .filter(({ destinationField }) => Boolean(destinationField)),
            removeHeader
        });

        if (response.ok) {
            setStep('complete');
        }
        else {
            // todo handle error
        }

        setIsProcessing(false);
    };

    const handleChangeMapping = (i, value) => {
        const newMappings = [...mappings];
        const j = (newMappings.findIndex(m => m === value));

        if (j >= 0) {
            // unmap existing instance
            newMappings[j] = '';
        }

        newMappings[i] = value;
        setMappings(newMappings);
    };

    const handleFetchGroups = async () => {
        const response = await handleGet('groups');
        const groups = await response.json();

        setGroups(groups);
    };

    //todo await create in dialog so user can't navigate away before drawer opens?

    useEffect(() => {
        step === 'final-checks' && handleFetchGroups();
    }, [step]);

    if (isProcessing) {
        return (
            <LoadingOverlay />
        );
    }

    if (step === 'mappings') {
        return (
            <MappingsDialog
                importData={importData}
                mappings={mappings}
                onChangeMapping={handleChangeMapping}
                onBack={() => setStep('upload')}
                onNext={() => setStep('groups')}
                onClose={onClose}
            />
        );
    }

    if (step === 'groups') {
        return (
            <GroupsDialog
                // newGroup={newGroup}
                selectedGroups={selectedGroups}
                onCreateGroup={onCreateGroup}
                onChangeSelectedGroups={setSelectedGroups}
                onBack={() => setStep('mappings')}
                onNext={() => setStep('final-checks')}
                onClose={onClose}
            />
        );
    }

    if (step === 'final-checks') {
        return (
            <FinalChecksDialog
                importData={importData}
                groups={groups}
                selectedGroups={selectedGroups}
                onBack={() => setStep('groups')}
                onClose={onClose}
                onConfirm={handleCompleteImport}
            />
        );
    }

    if (step === 'complete') {
        return (
            <ImportCompleteDialog
                importId={importData.id}
                onClose={onClose}
                onOpenImportHistory={onOpenImportHistory}
            />
        );
    }

    return (
        <UploadDialog
            setImportData={setImportData}
            setMappings={setMappings}
            onNext={() => setStep('mappings')}
            onClose={onClose}
        />
    );
};

export default ImportContactsDialog;